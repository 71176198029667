import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { EMPTY, map, mergeMap, of, tap, withLatestFrom } from 'rxjs';
import { LocalStorageKey } from 'src/app/core/models/local-storage-key.enum';
import { UserLocationAPIInterface } from 'src/app/modules/manage-users/_models/new-user-.model';
import { ManageUserService } from 'src/app/modules/manage-users/_services/manage-user.service';
import {
	changeLang,
	getLocationSettings,
	getLocationDataFromServer,
	getLocationTreeFromServer,
	initLang,
	getProfilePicS3URL,
	saveLocationData,
	saveLocationTree,
	saveProfilePicS3URL,
	updateProfilePicS3URL,
	updateSelectedLocation,
	getLinkedProfiles,
	saveUserPrivilege,
	openPE,
	updatePEValue,
	savePEValue,
	submitPESurvey,
	closePE,
	saveLocationSettings,
	getProductAccessSettings,
	saveProductAccessSettings,
	getLocationInfo,
	saveUserLocation,
	updateLocationSettings,
	openTutorialPopup,
} from './shared.actions';
import { SharedState, getSettings } from './shared.reducer';
import { FileUploadService } from 'src/app/core/services/file-upload.service';
import { UserPrivilageInterface } from 'src/app/core/models/user-privilege.interface';
import { MatDialog } from '@angular/material/dialog';
import { CommonSurveyPopupComponent } from 'src/app/modules/my-courses/common-survey-popup/common-survey-popup.component';
import { MyCourseService } from 'src/app/modules/my-courses/_services/my-course.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { SettingsService } from 'src/app/modules/user-settings/_services/settings.service';
import { BroadcastService } from 'src/app/core/services/broadcast.service';
import { SurveyService } from 'src/app/modules/survey/_services/survey.service';
import { LocationList } from '../models/locations';
import { SettingsMap } from 'src/app/modules/user-settings/_models/settins-map.enum';
import { NewUserTutorialPopupComponent } from '../components/new-user-tutorial-popup/new-user-tutorial-popup.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SharedEffects {
	private dialogRef: any;
	constructor(
		private action$: Actions,
		private sharedStore: Store<SharedState>,
		private userService: ManageUserService,
		private surveyService: SurveyService,
		private settingsService: SettingsService,
		private matDialog: MatDialog,
		private fileUploadService: FileUploadService,
		private notificationService: NotificationService,
		private myCourseService: MyCourseService,
		private broadcastService: BroadcastService,
		private translateService: TranslateService
	) {}

	getLocationInfo$ = createEffect(() => {
		return this.action$.pipe(
			ofType(getLocationInfo),
			map((action) => action.root),
			mergeMap((root) =>
				this.surveyService.getLocationsList(root).pipe(
					map((res) => {
						return res as LocationList;
					}),
					map((location) => saveUserLocation({ payload: location }))
				)
			)
		);
	});

	getLocationData$ = createEffect(() => {
		return this.action$.pipe(
			ofType(getLocationDataFromServer),
			mergeMap(() => {
				const locationFromStorage = localStorage.getItem(
					LocalStorageKey.LOCATION_DATA
				);
				if (locationFromStorage) {
					const location = JSON.parse(
						locationFromStorage
					) as UserLocationAPIInterface;
					return of(location);
				}
				return this.userService.getUserLocationData(true).pipe(
					map((location) => {
						localStorage.setItem(
							LocalStorageKey.CURRENT_LOCATION_TYPE,
							location.grandParent
								? 'grandParent'
								: location.parent
								? 'parent'
								: 'child'
						);

						localStorage.setItem(
							LocalStorageKey.LOCATION_DATA,
							JSON.stringify(location)
						);
						return location;
					})
				);
			}),
			map((location) => saveLocationData({ location: location }))
		);
	});

	getLocationTree$ = createEffect(() => {
		return this.action$.pipe(
			ofType(getLocationTreeFromServer),
			mergeMap(() => {
				return this.userService.getUserLocationData(false).pipe(
					map((location) => {
						localStorage.setItem(
							LocalStorageKey.LOCATION_TREE,
							JSON.stringify(location)
						);
						return location;
					})
				);
			}),
			map((location) => saveLocationTree({ location: location }))
		);
	});

	// Comment/Uncomment below code to fetch UI Settings API
	getLocationSettings$ = createEffect(() => {
		return this.action$.pipe(
			ofType(getLocationSettings),
			mergeMap((action) => {
				return this.settingsService
					.getUISettings(action.userId, action.locationId)
					.pipe(
						map((data) => {
							if (data && data.code && data.code == 400) {
								return [];
							}
							if (data.data && data.data?.length) {
								// localStorage.setItem(
								// 	LocalStorageKey.SETTINGS,
								// 	JSON.stringify(data.data[0])
								// );
								return data.data[0];
							} else {
								// localStorage.setItem(
								// 	LocalStorageKey.SETTINGS,
								// 	JSON.stringify([])
								// );
								return [];
							}
						})
					);
			}),
			map((data) => {
				return saveLocationSettings({ payload: data });
			})
		);
	});

	// Comment/Uncomment below code to fetch Product Access Settings API
	getProductAccessSettings$ = createEffect(() => {
		return this.action$.pipe(
			ofType(getProductAccessSettings),
			mergeMap((action) => {
				return this.settingsService
					.getGeneralUserProperties(action.clientId)
					.pipe(
						map((data) => {
							if (data.data && data.data?.length) {
								localStorage.setItem(
									LocalStorageKey.PRODUCT_ACCESS_SETTINGS,
									JSON.stringify(data.data[0])
								);
							} else {
								localStorage.setItem(
									LocalStorageKey.PRODUCT_ACCESS_SETTINGS,
									JSON.stringify([])
								);
							}
							return data;
						})
					);
			}),
			map((data) => {
				return saveProductAccessSettings({ payload: data.data[0] });
			})
		);
	});

	saveLocationTree$ = createEffect(
		() => {
			return this.action$.pipe(
				ofType(saveLocationTree),
				map((action) => action.location),
				tap((x) => {
					localStorage.setItem(
						LocalStorageKey.LOCATION_TREE,
						JSON.stringify(x)
					);
				})
			);
		},
		{ dispatch: false }
	);
	saveLocationSettings$ = createEffect(
		() => {
			return this.action$.pipe(
				ofType(saveLocationSettings),
				map((action) => action.payload),
				tap((x) => {
					localStorage.setItem(
						LocalStorageKey.SETTINGS,
						JSON.stringify(x)
					);
				})
			);
		},
		{ dispatch: false }
	);

	updateLocationSettings$ = createEffect(() => {
		return this.action$.pipe(
			ofType(updateLocationSettings),
			map((action) => action.payload),
			withLatestFrom(this.sharedStore.pipe(select(getSettings))),
			map(([newSettings, oldSettings]) => {
				const updatedSettings = oldSettings.map((setting) => {
					const isBrandingSetting = setting.brandSetting;

					const s = newSettings.find((x) => {
						if (isBrandingSetting) {
							return x.name === setting.name;
						} else return x.settingsId == SettingsMap[setting.name];
					});
					if (s) {
						return {
							...setting,
							value:
								typeof s.value === 'boolean'
									? s.value
										? '1'
										: '0'
									: s.value,
						};
					} else {
						return setting;
					}
				});
				return saveLocationSettings({ payload: updatedSettings });
			})
		);
	});

	saveLocationData$ = createEffect(
		() => {
			return this.action$.pipe(
				ofType(saveLocationData),
				map((action) => action.location),
				tap((x) => {
					let type: 'grandparent' | 'parent' | 'child';
					const loc = x;
					let selected;
					if (loc.grandParent) {
						type = 'grandparent';
						selected = {
							id: loc.grandParent.grandParentLocationId,
							name: loc.grandParent.grandParentLocationName,
							sitecode: loc.grandParent.siteCode,
						};
					}
					if (loc.parent) {
						type = 'parent';
						selected = {
							id: loc.parent.parentLocationId,
							name: loc.parent.parentLocationName,
							sitecode: loc.parent.siteCode,
						};
					}
					if (loc.child) {
						type = 'child';
						selected = {
							id: loc.child.locationId,
							name: loc.child.locationName,
							sitecode: loc.child.siteCode,
						};
					}
					const selectedLocation = JSON.parse(
						localStorage.getItem(
							LocalStorageKey.SELECTED_LOCATION
						) ?? '{}'
					);
					if (!selectedLocation || !selectedLocation.sitecode) {
						this.sharedStore.dispatch(
							updateSelectedLocation({
								id: selected.id,
								name: selected.name,
								sitecode: selected.sitecode,
								dp1: selected.id,
							})
						);
					} else {
						// const location = JSON.parse(selectedLocation);
						this.sharedStore.dispatch(
							updateSelectedLocation({
								id: selectedLocation.id,
								name: selectedLocation.name,
								sitecode: selectedLocation.sitecode,
								dp1: selectedLocation.dp1,
								dp2: selectedLocation.dp2,
							})
						);
					}
					// return of(EMPTY);
				})
			);
		},
		{ dispatch: false }
	);

	saveProductAccessSettings$ = createEffect(
		() => {
			return this.action$.pipe(
				ofType(saveProductAccessSettings),
				map((action) => {
					localStorage.setItem(
						LocalStorageKey.PRODUCT_ACCESS_SETTINGS,
						JSON.stringify(action.payload)
					);
				})
			);
		},
		{ dispatch: false }
	);

	updateSelectedLocation$ = createEffect(
		() => {
			return this.action$.pipe(
				ofType(updateSelectedLocation),
				tap((x) => {
					localStorage.setItem(
						LocalStorageKey.SELECTED_LOCATION,
						JSON.stringify(x)
					);
				})
			);
		},
		{ dispatch: false }
	);

	initLang$ = createEffect(() => {
		return this.action$.pipe(
			ofType(initLang),
			mergeMap(() => {
				let lang = localStorage.getItem(LocalStorageKey.LANGUAGE);
				if (!lang) {
					lang = 'en';
					localStorage.setItem(LocalStorageKey.LANGUAGE, lang);
				}
				return of(lang);
			}),
			map((lang) => changeLang({ lang: lang }))
		);
	});
	changeLang$ = createEffect(
		() => {
			return this.action$.pipe(
				ofType(changeLang),
				map((action) => action.lang),
				mergeMap((lang) => {
					localStorage.setItem(LocalStorageKey.LANGUAGE, lang);
					return of(EMPTY);
				})
			);
		},
		{ dispatch: false }
	);
	gets3profilepic$ = createEffect(() => {
		return this.action$.pipe(
			ofType(getProfilePicS3URL),
			map((action) => action.picturePath),
			mergeMap((path) => {
				if (!path) {
					return of({
						s3PresingedURL:
							'../../../assets/images/slr-default-avatar.webp',
					});
				}
				return this.fileUploadService
					.getPresignedUrl(path)
					.pipe(map((x) => x));
			}),
			map((res) => saveProfilePicS3URL({ s3path: res.s3PresingedURL }))
		);
	});
	updateProfilePicS3URL$ = createEffect(
		() => {
			return this.action$.pipe(
				ofType(updateProfilePicS3URL),
				map((action) => action.s3path),
				tap((path) => {
					const privilege = JSON.parse(
						localStorage.getItem(LocalStorageKey.PRIVILEGES)
					) as UserPrivilageInterface;
					privilege.picturePath = path;
					localStorage.setItem(
						LocalStorageKey.PRIVILEGES,
						JSON.stringify(privilege)
					);
				})
			);
		},
		{ dispatch: false }
	);

	getLinkedProfiles$ = createEffect(
		() => {
			return this.action$.pipe(
				ofType(getLinkedProfiles),
				map((x) => x.userId),
				mergeMap((id) => {
					return this.userService.getLinkedLocations(id).pipe(
						map((x) => x.data),
						tap(
							(
								data: {
									locationName: string;
									username: string;
									clientCode: string;
									locationId: number;
								}[]
							) => {
								let privilege: UserPrivilageInterface =
									JSON.parse(
										localStorage.getItem(
											LocalStorageKey.PRIVILEGES
										)
									);

								privilege = {
									...privilege,
									linkedUsers: data,
								};
								localStorage.setItem(
									LocalStorageKey.PRIVILEGES,
									JSON.stringify(privilege)
								);
								this.sharedStore.dispatch(
									saveUserPrivilege({ payload: privilege })
								);
							}
						)
					);
				})
			);
		},
		{ dispatch: false }
	);

	openPE$ = createEffect(
		() => {
			return this.action$.pipe(
				ofType(openPE),
				map((action) => action.courseId),
				mergeMap((courseId) => {
					return this.myCourseService.getPEQuestions().pipe(
						map((x) => {
							this.dialogRef = this.matDialog.open(
								CommonSurveyPopupComponent,
								{
									width:
										this.broadcastService.screenSize
											.value === 'small'
											? '100%'
											: '70%',
									height: '80%',
									data: { data: x, courseId: courseId },
								}
							);
						})
					);
				})
			);
		},
		{ dispatch: false }
	);

	updatePEValue$ = createEffect(() => {
		return this.action$.pipe(
			ofType(updatePEValue),
			map((action) => action.courseId),
			mergeMap((cid: number) => {
				return this.myCourseService
					.getTotalUsersWhoCompletedCourse(cid)
					.pipe(map((x) => [cid, x]));
			}),
			map(([cid, count]) => {
				//Settings: change this value (5) based on settings.
				const ran = count % 5 == 0 ? 1 : 0;
				return savePEValue({ value: ran, courseId: cid });
			})
		);
	});

	submitPESurvey$ = createEffect(() => {
		return this.action$.pipe(
			ofType(submitPESurvey),
			map((action) => action.payload),
			mergeMap((payload) => {
				return this.myCourseService.submitPESurveyAnswers(payload);
			}),
			map((x) => {
				this.notificationService.success(
					this.translateService.instant(
						'COMMON_SURVEY_SUCCESS_MESSAGE'
					)
				);
				return closePE();
			})
		);
	});

	closePE$ = createEffect(
		() =>
			this.action$.pipe(
				ofType(closePE),
				tap(() => {
					this.dialogRef.close();
				})
			),
		{
			dispatch: false,
		}
	);

	openTutorialPopup$ = createEffect(() =>
		this.action$.pipe(
			ofType(openTutorialPopup),
			map((action) => action.beforeLogin),
			mergeMap((beforeLogin) =>
				this.matDialog
					.open(NewUserTutorialPopupComponent, {
						width:
							this.broadcastService.screenSize.value === 'small'
								? '80vw'
								: '75%',
						maxWidth:
							this.broadcastService.screenSize.value === 'small'
								? '100%'
								: undefined,
						height:this.broadcastService.screenSize.value === 'small' ? '307px' :  '80%',
						disableClose: true,
						panelClass: ['no-padding-popup'],
						data: {
							beforeLogin,
						},
					})
					.afterClosed()
			)
		)
	);
}
