import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import {
	catchError,
	map,
	mergeMap,
	of,
	tap,
	withLatestFrom,
	filter,
	take,
	iif,
	EMPTY,
} from 'rxjs';
import { ErrorMessages } from 'src/app/core/helpers/error-messages.class';
import { GenericHelper } from 'src/app/core/helpers/generic-helper.class';
import { AppLanguages } from 'src/app/core/models/app-lang.enum';
import { LocalStorageKey } from 'src/app/core/models/local-storage-key.enum';
import { SlrUrlQueryParams } from 'src/app/core/models/url-params.enum';
import {
	LoginToekInterface,
	UserDetailsInterface,
} from 'src/app/core/models/user-details.interface';
import {} from 'src/app/core/models/user-privilege.interface';
import { BroadcastService } from 'src/app/core/services/broadcast.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { TokenService } from 'src/app/core/services/token.service';
import {
	getLocationDataFromServer,
	initLang,
	getProfilePicS3URL,
	saveUserPrivilege,
	getLinkedProfiles,
	getLocationSettings,
	getProductAccessSettings,
	updateSelectedLocation,
} from 'src/app/shared/_state/shared.actions';
import {
	getSelectedLocation,
	getUserPrivileges,
	SharedState,
} from 'src/app/shared/_state/shared.reducer';
import { ResetAppState } from 'src/app/state/app.action';
import { AppState } from 'src/app/state/app.state';
import { ChooseAccountPopupComponent } from '../choose-account-popup/choose-account-popup.component';
import { LoggedOutMessagePopupComponent } from '../logged-out-message-popup/logged-out-message-popup.component';
import { ProfileNotFoundPopupComponent } from '../profile-not-found-popup/profile-not-found-popup.component';
import { AuthService } from '../_services/auth.service';
import { UserPermissionService } from '../_services/user-permissions.service';
import {
	closeDialog,
	closeLoggedOutModal,
	handleLinkedUserChange,
	initUserPrivileges,
	listenStorageChange,
	login,
	loginFailure,
	loginSSO,
	loginSuccess,
	logout,
	logoutSuccess,
	openLoggedOutModal,
	openSessionExpirePopup,
	openSiteDialog,
	refreshUserSession,
	saveSecurityQuestions,
	saveTempUsername,
	saveUserSessionId,
	setupSecurityQuestion,
	startTimer,
	stopTimer,
	trackUserSession,
} from './authentication.action';
import {
	AuthenticationState,
	getLastActivityTime,
	getRefreshToken,
	getSessionExpiryTime,
	getToken,
	getUserSessionId,
} from './authentication.reducer';
import { SessionExpirePopupComponent } from '../session-expire-popup/session-expire-popup.component';
import { LinkedUserLoginRequestInterface } from '../_models/linked-user-login.interface';

@Injectable()
export class AuthenticationEffects {
	private dialogRef: any;
	private userDetail: UserDetailsInterface = null;
	private stateUrl: string;
	private inactivityTimer: any;
	constructor(
		private router: Router,
		private actions$: Actions,
		private authservice: AuthService,
		private tokenService: TokenService,
		private sharedStore: Store<SharedState>,
		private permissionService: UserPermissionService,
		private notificationService: NotificationService,
		private authStore: Store<AuthenticationState>,
		private appStore: Store<AppState>,
		private matDialog: MatDialog,
		private route: ActivatedRoute,
		private broadcastService: BroadcastService
	) {
		this.router.events
			.pipe(filter((event) => event instanceof NavigationStart))
			.subscribe({
				next: (res: NavigationStart) => {
					this.stateUrl = res.url;
				},
			});
	}

	login$ = createEffect(() =>
		this.actions$.pipe(
			ofType(login),
			map((action) => action.loginPayload),
			mergeMap((payload) =>
				this.authservice.login(payload).pipe(
					map((result: LoginToekInterface) => {
						if (result.error) throw new Error(result.error.message);
						return result;
					}),
					mergeMap((result: LoginToekInterface) => {
						localStorage.clear();
						if (payload.rememberSiteCode) {
							localStorage.setItem(
								LocalStorageKey.REMEMBER_SITE_CODE,
								`${payload.userName.split('.')[0]}.`
							);
						}
						this.tokenService.token = result;
						this.tokenService.sessionExipryTime =
							this.getTokenExpireTime(result.expiresIn);
						return of(payload.userName);
					}),
					map((userName: any) => {
						const [site, username] =
							GenericHelper.getClientCodeAndUserName(userName);
						return initUserPrivileges({
							username: username,
							site: site,
							sso: false,
						});
					}),
					catchError((err) => {
						if (
							err ==
							'Error: User must login by providing the new password.'
						) {
							this.router.navigate(['/auth/set-new-password']);
							return of(
								saveTempUsername({
									username: payload.userName,
									password: payload.password,
								})
							);
						}
						return of(loginFailure({ error: err.message }));
					})
				)
			)
		)
	);

	loginSSO$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loginSSO),
			map((action) => [action.username, action.token]),
			mergeMap(([username, token]) =>
				this.authservice.getUserSites(username).pipe(
					map((res: any) => res.data),
					map((data: string[]) => {
						if (data.length === 1) {
							localStorage.setItem(LocalStorageKey.TOKEN, token);
							return initUserPrivileges({
								username: username,
								site: data[0],
								sso: true,
							});
						}
						return openSiteDialog({
							data: data,
							email: username,
							token: token,
						});
					}),
					catchError((err) => {
						if (err.error && err.error.status == 404) {
							this.matDialog.open(ProfileNotFoundPopupComponent, {
								width: '40%',
								height: 'fit-content',
								data: username,
							});
						}
						return of(loginFailure({ error: err.error.message }));
					})
				)
			)
		)
	);

	openSiteDioloag$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(openSiteDialog),
				map((action) => [action.data, action.email, action.token]),
				tap(([data, email, token]) => {
					this.dialogRef = this.matDialog.open(
						ChooseAccountPopupComponent,
						{
							width: '30%',
							height: 'fit-content',
							disableClose: true,
							data: {
								data: data,
								email: email,
								token: token,
							},
						}
					);
				})
			),
		{
			dispatch: false,
		}
	);

	closeDialog$ = createEffect(() =>
		this.actions$.pipe(
			ofType(closeDialog),
			map((action) => [action.site, action.email, action.token]),
			map(([site, email, token]) => {
				if (!site) {
					localStorage.clear();
					return loginFailure({ error: 'User Cancelled Login' });
				}
				localStorage.setItem(LocalStorageKey.TOKEN, token);
				return initUserPrivileges({
					username: email,
					site: site,
					sso: true,
				});
			}),
			tap(() => this.dialogRef.close())
		)
	);

	logInSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loginSuccess),
			map((action) => action.privilege),
			withLatestFrom(this.route.queryParams),
			tap(([pr, params]) => {
				setTimeout(() => {
					if (!params[SlrUrlQueryParams.LOGIN_REDIRECT]) {
						this.routeWithoutRedirect();
						return;
					}

					const [rootUrl, queryParams] = (<string>(
						decodeURIComponent(
							params[SlrUrlQueryParams.LOGIN_REDIRECT]
						)
					)).split('?');
					const _params = !queryParams
						? {}
						: queryParams.split('&').reduce((a, x) => {
								const [key, value] = x.split('=');
								return {
									...a,
									[key]: value,
								};
						  }, {});
					const [rootUrlWithoutFragment, fragment] =
						rootUrl.split('#');
					this.routeWithRedirect(
						rootUrlWithoutFragment,
						_params,
						fragment
					);
				}, 10);
			}),
			map(([pr, route]) =>
				trackUserSession({
					payload: {
						userId: pr.userId,
						ipAddress: '',
						locationId: pr.locationId,
					},
				})
			)
		)
	);

	trackUserSession = createEffect(() =>
		this.actions$.pipe(
			ofType(trackUserSession),
			map((action) => action.payload),
			mergeMap((sessionInfo) =>
				this.authservice.trackUserSessionInfo(sessionInfo).pipe(
					map((res) => {
						localStorage.setItem(
							LocalStorageKey.USER_SESSION_ID,
							res.sessionId
						);
						return <number>res.sessionId;
					})
				)
			),
			map((sessionId) => saveUserSessionId({ sessionId }))
		)
	);

	loginFailure$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(loginFailure),
				map((action) => action.error),
				tap((error) => {
					localStorage.clear();
					this.notificationService.error(error);
				})
			);
		},
		{ dispatch: false }
	);

	initUserPrivileges$ = createEffect(() =>
		this.actions$.pipe(
			ofType(initUserPrivileges),
			map((action) => {
				return {
					username: action.username,
					sso: action.sso,
					site: action.site,
				};
			}),
			mergeMap((payload) => {
				return this.authservice
					.getUserPrivileges(payload.username, payload.site)
					.pipe(
						map((privilege) => {
							if (privilege.userId == null) {
								this.authStore.dispatch(
									loginFailure({
										error: ErrorMessages.PROFILE_NOT_FOUND,
									})
								);
								localStorage.clear();
								return null;
							}
							if (!privilege.locationId) {
								this.authStore.dispatch(
									loginFailure({
										error: `${ErrorMessages.UNEXPECTED_ERROR}. ${ErrorMessages.CONTACT_ADMIN}.`,
									})
								);
								localStorage.clear();
								return null;
							}
							localStorage.setItem(
								LocalStorageKey.PRIVILEGES,
								JSON.stringify(privilege)
							);
							localStorage.setItem(
								LocalStorageKey.LANGUAGE,
								privilege.language ?? AppLanguages.ENGLISH
							);
							this.permissionService.userPrivileges = privilege;
							this.sharedStore.dispatch(
								saveUserPrivilege({ payload: privilege })
							);
							this.sharedStore.dispatch(
								updateSelectedLocation({
									dp1: null,
									id: privilege.locationId,
									name: null,
									sitecode: null,
									dp2: null,
								})
							);
							this.sharedStore.dispatch(
								getLocationDataFromServer()
							);
							setTimeout(() => {
								this.sharedStore.dispatch(
									getProfilePicS3URL({
										picturePath: privilege.picturePath,
									})
								);
								if (privilege.userLinked) {
									this.sharedStore.dispatch(
										getLinkedProfiles({
											userId: privilege.userId,
										})
									);
								}
								this.sharedStore.dispatch(initLang());
								this.sharedStore.dispatch(
									getLocationSettings({
										locationId: privilege.locationId,
										userId: privilege.userId,
									})
								);
								this.sharedStore.dispatch(
									getProductAccessSettings({
										clientId: privilege.locationId,
									})
								);
							}, 10);
							return privilege;
						})
					);
			}),
			mergeMap((privileges) => {
				return this.authservice
					.isSecurityQuestionSetupForCurrentUser(
						`${privileges.siteCode}.${privileges.userName}`
					)
					.pipe(
						map((res) => {
							return { privileges, isSetup: res };
						})
					);
			}),
			mergeMap(({ privileges, isSetup }) => {
				return this.authservice
					.loadUserEmail(
						`${privileges.siteCode}.${privileges.userName}`
					)
					.pipe(
						map((res) => {
							return {
								privileges,
								isSetup,
								hasEmail: res,
							};
						})
					);
			}),
			mergeMap(({ privileges, isSetup, hasEmail }) => {
				return iif(
					() => {
						if (!hasEmail && !isSetup) {
							return false;
						}
						return true;
					},
					of(
						loginSuccess({
							user: this.userDetail,
							privilege: privileges,
						})
					),
					of(setupSecurityQuestion())
				);
			})
		)
	);

	setupSecurityQuestion$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(setupSecurityQuestion),
				map((action) => action),
				map(() => {
					this.router.navigate(['/auth', 'set-security-questions'], {
						queryParamsHandling: 'merge',
					});
				})
			),
		{ dispatch: false }
	);

	saveSecurityQuestionForUser$ = createEffect(() =>
		this.actions$.pipe(
			ofType(saveSecurityQuestions),
			map((action) => action.payload),
			withLatestFrom(this.sharedStore.pipe(select(getUserPrivileges))),
			mergeMap(([payload, privileges]) =>
				iif(
					() => payload,
					this.authservice.saveUsersSecurityQuestions(payload).pipe(
						map((res) => {
							return loginSuccess({
								privilege: privileges,
								user: this.userDetail,
							});
						})
					),
					of(
						loginSuccess({
							privilege: privileges,
							user: this.userDetail,
						})
					)
				)
			)
		)
	);

	handleLinkedUserChange$ = createEffect(() =>
		this.actions$.pipe(
			ofType(handleLinkedUserChange),
			map((action) => action.payload),
			withLatestFrom(
				this.authStore.pipe(select(getToken)),
				this.authStore.pipe(select(getRefreshToken)),
				this.authStore.pipe(select(getUserSessionId)),
				this.sharedStore.pipe(select(getSelectedLocation))
			),
			mergeMap(
				([
					payload,
					token,
					refreshToken,
					sessionId,
					currentLocation,
				]) => {
					const req: LinkedUserLoginRequestInterface = {
						accessToken: token,
						locationId: currentLocation.location.id,
						refreshToken,
						userName: `${payload.code}.${payload.username}`,
						userSessionId: sessionId,
					};
					return this.authservice.linkedUserLogin(req).pipe(
						map((result: LoginToekInterface) => {
							if (result.error)
								throw new Error(result.error.message);
							return result;
						}),
						mergeMap((result: LoginToekInterface) => {
							localStorage.clear();
							this.tokenService.token = result;
							this.tokenService.sessionExipryTime =
								this.getTokenExpireTime(result.expiresIn);
							return of(req.userName);
						}),
						mergeMap((userName: any) => {
							const [site, username] =
								GenericHelper.getClientCodeAndUserName(
									userName
								);
							// return initUserPrivileges({
							// 	username: username,
							// 	site: site,
							// 	sso: false,
							// });
							return this.authservice
								.getUserPrivileges(
									payload.username,
									payload.code
								)
								.pipe(
									mergeMap((privilege) => {
										return this.authservice
											.trackUserSessionInfo({
												userId: privilege.userId,
												locationId:
													privilege.locationId,
												ipAddress: '',
											})
											.pipe(map((x) => privilege));
									}),
									map((res) => {
										localStorage.removeItem(
											LocalStorageKey.CURRENT_LOCATION_TYPE
										);
										localStorage.removeItem(
											LocalStorageKey.SELECTED_LOCATION
										);
										localStorage.removeItem(
											LocalStorageKey.LOCATION_DATA
										);
										localStorage.setItem(
											LocalStorageKey.PRIVILEGES,
											JSON.stringify(res)
										);
										localStorage.setItem(
											LocalStorageKey.LANGUAGE,
											res.language ?? AppLanguages.ENGLISH
										);
										localStorage.setItem(
											LocalStorageKey.PRIVILEGES,
											JSON.stringify(res)
										);
										localStorage.setItem(
											LocalStorageKey.LANGUAGE,
											res.language ?? AppLanguages.ENGLISH
										);
										this.sharedStore.dispatch(
											saveUserPrivilege({ payload: res })
										);
										this.sharedStore.dispatch(
											updateSelectedLocation({
												dp1: null,
												id: res.locationId,
												name: null,
												sitecode: null,
												dp2: null,
											})
										);
										this.sharedStore.dispatch(
											getProfilePicS3URL({
												picturePath: res.picturePath,
											})
										);
										this.sharedStore.dispatch(
											getLocationDataFromServer()
										);
										if (res.userLinked) {
											this.sharedStore.dispatch(
												getLinkedProfiles({
													userId: res.userId,
												})
											);
										}
										this.sharedStore.dispatch(initLang());
										this.sharedStore.dispatch(
											getLocationSettings({
												locationId:
													res.locationId,
												userId: res.userId,
											})
										);
										this.sharedStore.dispatch(
											getProductAccessSettings({
												clientId: res.locationId,
											})
										);
										setTimeout(() => {
											location.reload();
										}, 1200);
										return null;
									})
								);
						})
					);
				}
			)
		)
	);

	logout$ = createEffect(() =>
		this.actions$.pipe(
			ofType(logout),
			map((action) => action),
			withLatestFrom(
				this.authStore.pipe(select(getUserSessionId)),
				this.sharedStore.pipe(select(getSelectedLocation))
			),
			mergeMap(([action, sessionId, location]) => {
				const _location = JSON.parse(
					localStorage.getItem(LocalStorageKey.SELECTED_LOCATION) ??
						'{}'
				);
				const sessionIdFromLocalStorage =
					localStorage.getItem(LocalStorageKey.USER_SESSION_ID) ?? 0;
				const _sessionId =
					sessionIdFromLocalStorage === 'undefined'
						? 0
						: sessionIdFromLocalStorage;
				if (!_sessionId) return of(action); // if no sessionId present just log the user out without api call
				let accessToken = this.tokenService.token.accessToken;
				if (!accessToken) {
					accessToken = this.tokenService.decodedIdToken(
						localStorage.getItem(LocalStorageKey.TOKEN)
					);
				}
				if (!accessToken) return of(action); // if no accesstoken present just log the user out without api call
				return this.authservice
					.logout(
						+_sessionId,
						location?.location?.id ?? _location?.id,
						accessToken
					)
					.pipe(map((res) => action));
			}),
			map((action) =>
				logoutSuccess({
					redirect: action.redirect,
					redirectUri: action.redirectUri ?? this.stateUrl ?? '',
				})
			)
		)
	);

	logoutSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(logoutSuccess),
				map((action) => {
					this.appStore.dispatch(new ResetAppState());
					const rememberSiteCode = localStorage.getItem(
						LocalStorageKey.REMEMBER_SITE_CODE
					);
					localStorage.clear();
					localStorage.setItem(LocalStorageKey.LOGGEDT_OUT, 'true');
					if (rememberSiteCode) {
						localStorage.setItem(
							LocalStorageKey.REMEMBER_SITE_CODE,
							rememberSiteCode
						);
					}
					const protocol = window.location.protocol;
					const host = window.location.host;
					const newLink = `${protocol}//${host}/auth/login${
						action.redirect &&
						action.redirectUri &&
						action.redirectUri.length > 0
							? `?${[
									SlrUrlQueryParams.LOGIN_REDIRECT,
							  ]}=${encodeURIComponent(action.redirectUri)}`
							: ''
					}`;
					window.location.href = encodeURI(newLink);
				})
			),
		{
			dispatch: false,
		}
	);

	listenStorageChange$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(listenStorageChange),
				map(() => {
					window.addEventListener('storage', (event) => {
						if (event.key === LocalStorageKey.TOKEN) {
							const oldVal = event.oldValue;
							const newVal = event.newValue;
							if (oldVal !== newVal) {
								// User has logged in with different account in other tab.
								this.authStore.dispatch(closeLoggedOutModal());
							}
							return;
						}
						if (event.key === LocalStorageKey.LOGGEDT_OUT) {
							if (
								localStorage.getItem(
									LocalStorageKey.LOGGEDT_OUT
								) === 'true'
							) {
								this.authStore.dispatch(openLoggedOutModal());
								return;
							}
						} else if (
							event.key === LocalStorageKey.SELECTED_LOCATION
						) {
							location.reload();
						}
					});
				})
			),
		{ dispatch: false }
	);
	openLoggedOutModal$ = createEffect(() =>
		this.actions$.pipe(
			ofType(openLoggedOutModal),
			mergeMap(() => {
				return this.matDialog
					.open(LoggedOutMessagePopupComponent, {
						width:
							this.broadcastService.screenSize.value === 'small'
								? '100%'
								: '45%',
						disableClose: true,
					})
					.afterClosed()
					.pipe(map((res) => closeLoggedOutModal()));
			})
		)
	);
	closeLoggedOutModal$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(closeLoggedOutModal),
				map(() => location.reload())
			),
		{ dispatch: false }
	);

	startTimer$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(startTimer),
				tap((action) => {
					this.inactivityTimer = setInterval(() => {
						let expiryTime;
						let lastActivityTime;
						this.authStore
							.pipe(select(getSessionExpiryTime), take(1))
							.subscribe({ next: (res) => (expiryTime = res) });
						this.authStore
							.pipe(select(getLastActivityTime), take(1))
							.subscribe({
								next: (res) => (lastActivityTime = res),
							});
						if (Date.now() >= expiryTime && expiryTime != null) {
							// Stop timer
							this.authStore.dispatch(stopTimer());

							// Log out user if they are inactive
							if (
								Date.now() - lastActivityTime >
								60 * 60 * 1000
							) {
								// 60 minutes of inactivity
								this.authStore.dispatch(
									openSessionExpirePopup()
								);
							} else {
								this.authStore.dispatch(refreshUserSession());
							}
						}
					}, 10 * 1000);
				})
			),
		{ dispatch: false }
	);

	openSessionExpirePopup$ = createEffect(() =>
		this.actions$.pipe(
			ofType(openSessionExpirePopup),
			map((action) => action),
			mergeMap(() => {
				return this.matDialog
					.open(SessionExpirePopupComponent, {
						width:
							this.broadcastService.screenSize.value === 'small'
								? '100%'
								: '45%',
						disableClose: true,
					})
					.afterClosed()
					.pipe(map((res) => logout({})));
			})
		)
	);

	stopTime$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(stopTimer),
				map(
					(action) =>
						this.inactivityTimer &&
						clearInterval(this.inactivityTimer)
				)
			),
		{ dispatch: false }
	);

	refreshUserSession$ = createEffect(() =>
		this.actions$.pipe(
			ofType(refreshUserSession),
			map((action) => action),
			withLatestFrom(this.authStore.pipe(select(getRefreshToken))),
			mergeMap(([action, token]) => {
				return this.authservice.getRefreshToken(token);
			}),
			map((token: LoginToekInterface) => {
				const existingIdToken = this.tokenService.token.idToken;
				this.tokenService.token = {
					...token,
					idToken: existingIdToken,
				};
				this.tokenService.sessionExipryTime = this.getTokenExpireTime(
					token.expiresIn
				);
				return startTimer();
			}),
			catchError((err) => {
				this.authStore.dispatch(logout({}));
				return of();
			})
		)
	);

	private routeWithRedirect(
		rootUrl: string,
		params: any,
		fragment: any
	): void {
		this.router.navigate([rootUrl], {
			queryParams: params,
			fragment: fragment,
		});
	}
	private routeWithoutRedirect(): void {
		this.router.navigate(['']);
	}

	private getTokenExpireTime(expiresIn: number): number {
		return Date.now() + expiresIn * 1000;
	}
}
